import * as React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import "normalize.css"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"

import Nav from "./Nav"
import Footer from "./Footer"
import Reaudio from "./Reaudio/Reaudio"

const ChildrenWrapper = styled.div`
  @media (min-width: 768px) {
    width: 373px;
    margin-left:10px;
    margin-top:0px;
    
  }
  margin-top:10px;
  max-width: var(--max-mobile-width);
`

const BiggerScreenWrapper = styled.div`
  @media (min-width: 768px) {
    max-width: 768px;
    display: flex;
    justify-content: space-around;
    > * {
      flex: 1 1 auto;
      min-height: 0;
    }
  }
`

const LayoutWrapper = styled.div`
  @media (min-width: 768px) {
    max-width: 768px;
  }

  max-width: var(--max-mobile-width);
  //overflow: hidden;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  //background-color: var(--grey);

  /* @media (min-width: 768px) {
    max-width:768px;
  } */
`
const Header = styled.header`
  background-color: var(--grey);
  width: 100%;
 


  .title-wrapper {
    width: 100%;
    margin-top: -70px;
    padding: 0 0 20px 0;
    z-index: 5;
    
  }
  

  @media (min-width: 768px) {
    .title-wrapper {
    width: 100%;
   
  }

  }

  .radio-title {
    color: var(--red);
    padding: 0;
    margin:0;
    text-transform: none;
    text-align: center;
    width: 100%;
    font-size: var(--xl-text);
    
   
    }

  h2 {
    text-align: center;
    font-size: var(--s-text);
    margin-bottom: 10px;
    padding:0;
  }

`
const PlayerWrapper = styled.div`
  max-width: var(--max-mobile-width);
  width: 100%;
  margin: 0 auto;

  //background-color: var(--grey);
  @media (min-width: 768px) {
    margin-right: 10px;
  }
`

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  return (
    <LayoutWrapper>
      <GlobalStyles />
      <Typography />

      <title>{data.site.siteMetadata.title}</title>

      <Header>
        <Nav />
        <div className="title-wrapper">
          <h1 className="radio-title">{data.site.siteMetadata.title}</h1>
          <h2> {data.site.siteMetadata.description}</h2>
        </div>
      </Header>

      <BiggerScreenWrapper>
        <PlayerWrapper>
          <Reaudio location={location} />
        </PlayerWrapper>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </BiggerScreenWrapper>
      <Footer />
    </LayoutWrapper>
  )
}

export default Layout
