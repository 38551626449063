import { createGlobalStyle } from "styled-components"
import bg from "../assets/images/splatter-small.svg"

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #ff1f1f;
    --black: #2E2E2E;
    --blue: #b1d9f2;
    --white: #fff;
    --grey: #efefef;
    --yellow: #dedfa3;
    --darkBlue: #000433;
    --bege: #D0CABD;
    
    --border: 1.5px;
    --mainPadding: 20px;

    --max-mobile-width: 373px;
    --min-children-height: 437px;

  }
  html {
    background-image: url(${bg});
    background-size: 125px;
    background-attachment: fixed;
    font-size: 16px;
    background-color:var(--grey);
  }


  body {
    overflow-x: hidden;
    font-size: 1rem;
  }



  .no-overflow {
    overflow-y: hidden;
  }


  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }
  button {
    background: transparent;
    color: var(--black);
    border: 0;
    padding: 0.6rem 1rem;
    cursor: pointer;
    transition: scale 0.2s;

    &:hover,
    &:focus {
      //transform: scale(1.1);
      color: var(--red);
    }

    .active{
      color: var(--red);
    }
    /* &:focus {
    color:  var(--red);
   
    } */
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }



  .collapse{
    max-height: 180px;

  }

  svg{
    pointer-events: none;
  }


  .title-heading-page{
    font-size:var(--xs-text);
    color:var(--black) !important;
    border-bottom: var(--border) solid var(--black);
    padding: 0;
    margin:0 !important;
    font-family: GangsterGroteskRegular, Arial, sans-serif !important;
    background-color:white;
  }

  .offcuts-song .gatsby-resp-image-wrapper img {
    box-shadow: unset !important;
  }

  .offcuts-song .gatsby-resp-image-wrapper {
    height: 724px;
    overflow: hidden;
  }

  .offcuts-song.tough .gatsby-resp-image-wrapper  {
    margin: 0 20px !important;
  }

  .offcuts-song.tough .gatsby-resp-image-wrapper img,
  .offcuts-song.lumumba .gatsby-resp-image-wrapper img{
    object-fit: contain;
    object-position: 50% 50%;
    background-color: transparent !important;

  }

  .offcuts-song .gatsby-resp-image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 50%;
    box-shadow: unset !important;
  }





`

export default GlobalStyles
