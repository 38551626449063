import React from "react"
import { BiPlayCircle } from "react-icons/bi"


const Play = ({ handleClick }) => {
  return (
    <button aria-label="play" className="player__button" onClick={handleClick}>
      <BiPlayCircle />
    </button>
  )
}

export default Play
