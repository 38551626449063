import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { RiArrowDownFill } from "react-icons/ri"


const NavStyling = styled.nav`
  width: 100%;
  position: relative;
  background-color: var(--grey);


  h2 {
    text-align: center;
    font-size: var(--xs-text);
    border: var(--border) solid var(--black);
    border-bottom:none;
    background-color: var(--blue);
    /* padding: 10px 34px 3px 34px; */
  }

  span {
    position: absolute;
    left: 20px;
    top: 14px;
    z-index: 2;
    transition: all 2s;
    svg{
      width:20px;
      height:20px;
    }
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin:0;
    padding:20px 0;
    border: var(--border) solid var(--black);
    li {
      font-size: var(--s-text);
      background-color: var(--white);
      padding: 6px 10px;
     
      border: var(--border) solid var(--black);
    }
    li:hover {
      background-color: var(--blue);
    }
  }
  a {
    text-decoration: none;
    color: var(--black);
  }
  .backgroundColorActive {
    background-color: white;
  }
  .backgroundColorInactive {
    background-color: var(--grey);
  }
`

const NavStations = ({
  location,
  playlist,
  setPlaylist,
  setPlayerId,
  setPlaying, allPlaylists
}) => {
  function changePlaylist(playlist, playerId) {
    setPlaylist(playlist)
    setPlayerId(playerId)
    setPlaying(false)
  }

    return (
        <NavStyling playlist={playlist} location={location}>
            <h2>choose playlist</h2>
            <span style={{ left: playlist === "offcuts" ? "40px" : playlist === "dub" ? "305px" : "175px"}}>
        <RiArrowDownFill />
      </span>
            <ul>
                {allPlaylists.map((item) => (
                    <li
                        key={item}
                        className={
                            playlist === item ? "backgroundColorActive" : "backgroundColorInactive"
                        }
                    >
                        <Link
                            style={{ fontWeight: playlist === item ? "700" : "400" }}
                            activeStyle={{
                                fontWeight: 700,
                            }}
                            onClick={() => changePlaylist(item)}
                            to={item === "offcuts" ? "/" : `/${item}`}
                        >
                            {item === "dub" ? "dub nights" : item}
                        </Link>
                    </li>
                ))}
            </ul>
        </NavStyling>
    );
}

export default NavStations
