import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Play from "./Play"
import Pause from "./Pause"
import Forward from "./Forward"
import Backward from "./Backward"
import styled from "styled-components"
import { Link } from "gatsby"

const FeatureWrapper = styled.div`
  display: flex;
  border: var(--border) solid var(--black);
  border-top: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 20px 10px;
  background-color: white;
  position: relative;

  .gatsby-image-wrapper {
    margin-top: 20px;
    background-color: var(--grey);
    box-shadow: 0 0 50px 0 darkgrey;
    border: var(--border) solid var(--black);
    border-radius: 20px;
  }

  .gatsby-image-wrapper > picture > img {
    border-radius: 20px;
  }

  .selector > div > button > svg {
    pointer-events: none;
  }

  .featured-music-info {
    text-align: center;
    z-index: 2;
    border-radius: 0 0 20px 20px;
    color: var(--black);
    h2 {
      font-size: 16px;
      padding: 12px 0;
    }
  }

  .featured-controls {
    display: flex;
    justify-content: center;
    margin-top: -12px;
  }

  .featured-controls > button > svg,
  .featured-controls > a > button > svg {
    width: 48px;
    height: 48px;
  }
  button.forward__button.over,  button.backward__button.over {
    color: var(--grey);
    pointer-events: none;
  }
  a:has(.forward__button.over),  a:has(.backward__button.over) {
    pointer-events: none;
  }
  
`

export default function Featured({
  playlist,
  isCurrent,
  playing,
  setPlaying,
  playerId,
  onForwardClick,
  onBackwardClick,
  togglePlay,
  currentIndex,
}) {

  return (
    <section>
      {playlist
        .filter(track => track.slug.includes(playerId))
        .map(filteredtrack=> {
          const image = getImage(filteredtrack.trackImage);

          return (
            <FeatureWrapper
              className={`selector containerSongFeatured`}
              data-name={filteredtrack.slug}
              key={`${filteredtrack.slug}`}
            >
              <GatsbyImage
                className="feat-img"
                image={image}
                alt={`${filteredtrack.trackName}-${filteredtrack.trackArtist}`}
                width={350}
              />

              <div className="featured-music-info">
                <h2>
                  <span>{filteredtrack.trackArtist}</span> -{" "}
                  <span>{filteredtrack.trackName}</span>
                </h2>
              </div>
              <div className="featured-controls">
                   <Backward
                       className={`backward__button ${currentIndex === 0 ? "over" : ""}`}
                       handleClick={() => {
                         // Call the onForwardClick callback to update the currentIndex
                         onBackwardClick();
                       }}
                />
                {playing && isCurrent ?
                  <Pause
                    handleClick={() => {
                      setPlaying(false);
                      togglePlay(playerId);
                    }}
                  />
                :
                    <Link to={`/blog/${filteredtrack.playlistName}/${filteredtrack.title}/${playerId}/#post-heading`}>
                      <Play
                          handleClick={() => {
                            setPlaying(true);
                            togglePlay(playerId);
                          }}
                      />
                    </Link>
                }
                  <Forward
                      className={`${playlist.length - 1 === currentIndex ? "forward__button over" : "forward__button"}`}
                      handleClick={() => {
                      onForwardClick();
                    }}
                  />

              </div>
            </FeatureWrapper>
          )
        })}
    </section>
  )
}
