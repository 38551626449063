import React from 'react'
import { BsSkipForward } from 'react-icons/bs';

const Forward = ({ handleClick, className }) => {
    return (
        <button aria-label="foward song" className={className} onClick={handleClick}>
            <BsSkipForward />
        </button>
    )
}

export default Forward
