import React, { useEffect, useState } from "react"

import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Helmet from 'react-helmet'
import { MdClose } from "react-icons/md"
import { FiMenu } from "react-icons/fi"
import { useRef } from "react"

const NavStylingWrapper = styled.nav`
  width: fit-content;
 
`

const ButtonNav = styled.nav`
  padding: 0;
  margin: 5px;
  position:relative;
  z-index:5;

  :hover {
    cursor:pointer;
  }

  svg {
    background: white;
    border: 1.5px solid var(--black);
    width: 40px;
    height: 40px;
    color:var(--black);
  }
  svg:hover {
    color: var(--red) !important;
    cursor:pointer;
  }

  .menu-desc{
    font-size:var(--xs-text);
    margin:0;
    text-indent: 0;
    padding-left: 5px;
    padding-bottom: 0;
  }
`
const NavStyling = styled.nav`
  .menuNav {
    list-style: none;
    transition: all 1s;
    top: -2000px;
    flex-direction: column;
    justify-content: center;
    max-width: 376px;
    width: 100vw;
    margin: 0;
    padding: 0;
    background-color: var(--grey);
    position: absolute;
    height: 100%;
    z-index: 4;
    //border: 1px solid var(--black);
    @media (min-width: 768px) {
      max-width: 768px;
    }
  }
  .menuNav.showMenu {
    top:70px;
    //display:block;
    list-style: none;
  }
  a {
    display: block;
    padding: 20px 0;
    text-decoration: none;
    color: var(--black);
    font-size: var(--l-text);
    text-align: center;
  }
  a:hover {
    color: var(--red);
  }
  .menuNav li:first-child {
    margin-top: 4rem;
  }
 
`
const Nav = ({ location }) => {
  const ref = useRef()
  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }
  const closeMenu = () => {
    setNavbarOpen(false)
  }

  useOnClickOutside(ref, () => setNavbarOpen(false))

  return (
    <NavStylingWrapper ref={ref}>

<Helmet
    bodyAttributes={{
        class: navbarOpen ? "no-overflow" : ""
    }}
/>
      <ButtonNav onClick={handleToggle}>
        {navbarOpen ? <div><MdClose /> <p className="menu-desc">Close</p></div>: <div><FiMenu /><p className="menu-desc">Menu</p></div>}
      </ButtonNav>
      <NavStyling className="nav-bar" location={location}>
        <ul  className={`menuNav ${navbarOpen ? "showMenu" : ""}`}>
          <li>
            <AnchorLink
              to="/about#about-heading"
              title="About"
              activeClassName="active-link"
              onAnchorLinkClick={closeMenu}
            />
          </li>
          <li>
            <AnchorLink
              to="/future#future-heading"
              title="Future Events"
              onAnchorLinkClick={closeMenu}
            />
          </li>
          <li>
            <AnchorLink
              to="/past#past-heading"
              title="Past Events"
              onAnchorLinkClick={closeMenu}
            />
          </li>
          {/* <li>
          <AnchorLink to="/blog#blog-heading" title="Song Log" />
        </li> */}
          <li>
            <AnchorLink
              to="/contact#contact-heading"
              title="Contact"
              onAnchorLinkClick={closeMenu}
            />
          </li>
          <li>
            <a href="https://www.companychipaumire.com/" target="__blank">
              Company Chipaumire
            </a>
          </li>
        </ul>
      </NavStyling>
    </NavStylingWrapper>
  )
}

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export default Nav
