import React, { useState, useEffect, useLayoutEffect } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Player from "./Player"
import { slugify } from "./helpers/helpers"

import Featured from "./Featured"

import { ImShrink2 } from "react-icons/im"
import { ImEnlarge2 } from "react-icons/im"

import styled from "styled-components"
import NavStations from "./NavStations"

const ReaudioWrapper = styled.div`
  overflow-y: auto;
`
const PlayerAll = styled.div`
  border-bottom: var(--border) solid var(--black);
`
const CollapseStyling = styled.div`
  position: absolute;
  z-index: 2;
  background-color: var(--yellow);
  margin: 5px;
  border: var(--border) solid var(--black);

  &:hover {
    background-color: var(--grey);
  }

  button {
    padding: 5px 5px 3px;
  }

  button > svg {
    width: 20px;
    height: 20px;
  }
`

const Reaudio = ({ location }) => {
  const data = useStaticQuery(graphql`{
  site {
    siteMetadata {
      title
      siteUrl
    }
  }
  allMarkdownRemark(sort: {frontmatter: {date: ASC}}) {
    nodes {
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        trackArtist
        trackName
        playlistName
        totalDuration: duration
        trackImage: image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              transformOptions: {fit: COVER}
              width: 350
              height: 200
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        audioUrl
        id
      }
    }
  }
}`)

  const allPlaylists = Array.from(
      new Set(data.allMarkdownRemark.nodes.map(node => node.frontmatter.playlistName))
  ).sort((a, b) => b.localeCompare(a));

  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  const [playlist, setPlaylist] = useState(
      isRootPath ? allPlaylists[0] : allPlaylists.find(item => location.pathname.includes(item)) ?? allPlaylists[0]
  );

  const posts = data.allMarkdownRemark.nodes

  const myPlaylist = posts
      .filter(edge => edge.fields.slug.includes(playlist))
      .map(({ frontmatter: { trackName, trackArtist, id, ...rest } }) => {
        const slug = slugify(`${trackName}-${trackArtist}-${id}`);
        return { ...rest, trackName, trackArtist, id, slug };
      });


  const [playerId, setPlayerId] = useState(null)
  const [currentPlayer, setCurrentPlayer] = useState(null)
  const [playing, setPlaying] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [playerShrink, setPlayerShrink] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleShrink = () => {
    setPlayerShrink(!playerShrink)
  }

  useLayoutEffect(() => {
    // Parse the slug from the URL
    const pathnameParts = location.pathname.split('/');

    // Find the index of "track" in the URL
    const idSlug = pathnameParts[4];

    if (idSlug) {
      // Extract the part after "track"
      // Find the corresponding item in myPlaylist based on the slug
      const initialTrack = myPlaylist.find((item) => item.slug === idSlug);
      if (initialTrack) {
        // Set the initial state based on the found track
        setPlayerId(initialTrack.slug);
        setCurrentIndex(myPlaylist.indexOf(initialTrack));
      } else {
        // Set the initial state to the first item in myPlaylist if no match is found
        setPlayerId(myPlaylist[0].slug);
        setCurrentIndex(0);
      }
    } else {
      // Set the initial state to the first item in myPlaylist if "track" is not found
      setPlayerId(myPlaylist[0].slug);
      setCurrentIndex(0);
    }
  }, [location.pathname, myPlaylist]);



  const togglePlay = (id) => {
    // Check if currentPlayer is already set and playing the same audio
    if (currentPlayer && currentPlayer.id === id) {
      if (!currentPlayer.paused) {
        currentPlayer.pause();
        setCurrentPlayer(null); // Clear currentPlayer when paused
      }
    } else {
      // Get the clicked audio element
      const clickedAudio = document.getElementById(id);

      // Pause the current player (if any)
      if (currentPlayer) {
        currentPlayer.pause();
      }

      // Set isLoading based on networkState
      clickedAudio.networkState === 2 ? setIsLoading(true) : setIsLoading(false);

      // Play the clicked audio
      clickedAudio.play();
      setPlayerId(clickedAudio.id)
      // Set currentPlayer to the clicked audio
      setCurrentPlayer(clickedAudio);
      // Update currentIndex
      const newCurrentIndex = myPlaylist.findIndex((item) => item.slug === id);
      setCurrentIndex(newCurrentIndex);

    }
  };

  const handleForwardClick = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < myPlaylist.length) {
      setCurrentIndex(nextIndex);
      const nextSongId = myPlaylist[nextIndex].slug;
      navigate(`/blog/${myPlaylist[nextIndex].playlistName}/${myPlaylist[nextIndex].title}/${nextSongId}`);
      setPlayerId(nextSongId);
      if (playing) {
        togglePlay(nextSongId);
        navigate(`/blog/${myPlaylist[nextIndex].playlistName}/${myPlaylist[nextIndex].title}/${nextSongId}`);
      }
    }
  };

  const handleBackwardClick = () => {
    const previousIndex = currentIndex - 1;
    if (previousIndex >= 0) {
      setCurrentIndex(previousIndex);
      const previousSongId = myPlaylist[previousIndex].slug;
      navigate(`/blog/${myPlaylist[previousIndex].playlistName}/${myPlaylist[previousIndex].title}/${previousSongId}`);
      setPlayerId(previousSongId);
      if (playing) {
        togglePlay(previousSongId);
        navigate(`/blog/${myPlaylist[previousIndex].playlistName}/${myPlaylist[previousIndex].title}/${previousSongId}`);
      }
    }
  };


// Use useEffect to attach the "ended" event listener
  useEffect(() => {
    if (currentPlayer) {
      const handleAudioEnded = () => {
        currentPlayer.pause();

        // Handle what to do when the audio ends (e.g., play the next one)
        setCurrentIndex(myPlaylist.findIndex((item) => item.slug === currentPlayer.id));

        if (currentIndex < myPlaylist.length - 1) {
          const nextIndex = currentIndex + 1;
          setCurrentIndex(nextIndex);
          const nextAudioId = myPlaylist[nextIndex].slug;
          const nextAudio = document.getElementById(nextAudioId);
          if (nextAudio) {
            // Play the next audio and update currentPlayer
            nextAudio.play(nextAudioId);
            setCurrentPlayer(nextAudio);
            navigate(`/blog/${myPlaylist[nextIndex].playlistName}/${myPlaylist[nextIndex].title.replace(/ /g, "-")}/${nextAudioId}`);
          }
        } else {
          setCurrentPlayer(null);
        }
      };

      currentPlayer.addEventListener("ended", handleAudioEnded);

      // Clean up the event listener when currentPlayer changes or unmounts
      return () => {
        currentPlayer.removeEventListener("ended", handleAudioEnded);
      };
    }
  }, [currentPlayer, myPlaylist, currentIndex, playerId]);

  return (
      <PlayerAll>
        <NavStations
            location={location}
            playlist={playlist}
            setPlaylist={setPlaylist}
            playerId={myPlaylist[0].slug}
            setPlayerId={setPlayerId}
            setPlaying={setPlaying}
            allPlaylists={allPlaylists}
        />
        <CollapseStyling>
          <button onClick={handleShrink}>
            {playerShrink ? <ImEnlarge2 /> : <ImShrink2 />}
          </button>
        </CollapseStyling>
        <div className="songPlaying">
          <Featured
              setPlayerId={setPlayerId}
              playerId={playerId ? playerId : myPlaylist[0].slug}
              playlist={myPlaylist}
              playing={playing}
              setPlaying={setPlaying}
              isCurrent={currentPlayer ? true : false}
              location={location}
              togglePlay={togglePlay}
              currentIndex={currentIndex}
              onForwardClick={handleForwardClick}
              onBackwardClick={handleBackwardClick}
          />
        </div>
        <ReaudioWrapper
            id="playerView"
            className={`${playerShrink ? "collapse" : ""}`}
        >
          {myPlaylist.map((track, index) => {


            return (
                <Player
                    isLoading={isLoading}
                    totalDuration={parseFloat(track.totalDuration)}
                    location={location}
                    playing={playing}
                    setPlaying={setPlaying}
                    key={track.slug+index}
                    slug={track.slug}
                    index={index}
                    audioUrl={track.audioUrl}
                    togglePlay={togglePlay}
                    trackName={track.trackName}
                    trackArtist={track.trackArtist}
                    trackImage={track.trackImage}
                    playlistName={track.playlistName}
                    title={track.title}
                    setIsLoading={setIsLoading}
                    isCurrent={
                      currentPlayer && currentPlayer.id === track.slug ? true : false
                    }
                />
            )
          })}
        </ReaudioWrapper>
      </PlayerAll>
  )
}

export default Reaudio
