import { createGlobalStyle } from 'styled-components';

import fontBold from '../assets/fonts/gangstergrotesk-bold.woff';
import fontRegular from '../assets/fonts/gangstergrotesk-regular.woff';


const Typography = createGlobalStyle`
  @font-face {
    font-family: GangsterGroteskBold;
    src: url(${fontBold});
    font-display: swap;
  }
  @font-face {
    font-family: GangsterGroteskRegular;
    src: url(${fontRegular});
    font-display: swap;
  }

  :root {
    --xs-text: 12px;
    --s-text: 16px;
    --m-text: 22px;
    --l-text: 28px;
    --xl-text: 38px;
    --xxl-text: 50px;
    --xxxl-text: 68px;
  }


  html {
    font-family: GangsterGroteskRegular, -apple-system, 'Helvetica Neue', 'Open Sans',sans-serif;
    color: var(--black);
    text-transform: lowercase;
  }
  p, li {
    letter-spacing: 0.5px;
    font-family: GangsterGroteskRegular;
  }

  p{
    text-indent: 20px;
    }

  h1{
      font-size: var(--l-text);
      font-family: GangsterGroteskBold;
      text-align:center;
      padding-bottom: 10px;
      font-size: var(--xl-text);
  }

  h2 {
      font-size: var(--m-text);
      font-family: GangsterGroteskRegular;
  }

  h3 {
      font-size:var(--m-text);
  }
  h1,h2,h3,h4,h5,h6 {
    letter-spacing: 0.4px;
    margin: 0;
  }
  a {
    color: var(--black);
    font-family: GangsterGroteskBold;
    
  }
  mark, .mark {
    background: var(--blue);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }
  .center {
    text-align: center;
  }
 
`;

export default Typography;