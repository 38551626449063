import React, { useState, useEffect, useRef } from "react"
import TrackInfo from "./TrackInfo"
import Play from "./Play"
import Pause from "./Pause"
import Spinner from "./Spinner"
import Bar from "./Bar"
import placeholderImg from "./assets/placeholder.png"
import placeholderMusic from "./assets/placeholder.mp3"
import styled from "styled-components"
import { Link } from "gatsby"

const PlayerWrapper = styled.div`
  overflow-y: scroll;
  max-height: 800px;
  scrollbar-width: thin;
  scrollbar-color: var(--blue) var(--white);
  border-left: var(--border) solid black;
  border-right: var(--border) solid black;

  .controls {
    overflow: auto;
  }

  .track-info {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    min-width: 120px;
    justify-content: space-between;
    background-color: white;
  }
  .info-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    width: 100%;
    padding: 2px;
  }

  .track-info .track-image {
    max-width: 60px;
    height: auto;
    margin-right: 12px;
    background-color: var(--black);
    display: block;
  }
  .player__button,
  .seeking {
    background-color: transparent;
    border: none;
    margin-right: 2px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .player__button:focus {
    outline: none;
  }
  .player__button:hover {
    cursor: pointer;
  }
  .player__button svg {
    color: var(--black);
    width: 28px;
    height: 28px;
  }

  .seeking svg {
    color: var(--black);
    width: 28px;
    height: 28px;
  }

  .seeking {
    padding: 0.6rem 1rem;
  }

  .player__button svg path {
    fill: var(--black);
  }

  .bar {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: -5px;
    overflow: hidden;
    padding-top: 2px;
  }
  .bar .bar__time {
    color: #444;
    font-size: 16px;
    min-width: 40px;
    padding-right: 4px;
  }
  .bar .bar__progress {
    -webkit-box-flex: 1;
    flex: 1;
    border-radius: 5px;
    margin-right: 20px;
    height: 10px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--black);
  }
  .bar .bar__progress .bar__progress__knob {
    position: relative;
    height: 15px;
    width: 15px;
    border: 1.5px solid var(--black);
    border-radius: 50%;
    background-color: var(--blue);
  }

  .track-name {
    font-family: GangsterGroteskRegular, Arial, sans-serif;
    text-decoration: underline;

    &:hover {
      color: var(--black);
      cursor: pointer;
    }
  }
`

const PlayerStyling = styled.div`
  -webkit-box-flex: 1;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
`
const ControlBarStyling = styled.div`
  background-color: var(--grey);

  -webkit-box-flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
`

const Player = ({
  loading,
  location,
  audioUrl,
  slug,
  title,
  trackName,
  trackArtist,
  trackImage,
  playlistName,
  loop,
  preload,
  showTrackInfo,
  togglePlay,
  index,
  isCurrent,
  playing,
  setPlaying,
  totalDuration,
  setIsLoading,
}) => {
  const audioRef = useRef()

  const [duration, setDuration] = useState()
  const [curTime, setCurTime] = useState()

  const [clickedTime, setClickedTime] = useState()
  const [isSeeking, setIsSetting] = useState(false)
  useEffect(() => {
    const audio = audioRef.current;

    // State setters
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    };

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("timeupdate", setAudioData); // Listen for time updates continuously

    // Handle seeking state when audio is seeking
    audio.addEventListener("seeking", () => {
      setIsSetting(true);
    });

    // Handle seeking state when audio is done seeking
    audio.addEventListener("seeked", () => {
      setIsSetting(false);
    });

    if (audio.ended) {
      setIsSetting(false);
      setIsLoading(false);
    }

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    // Effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioData);
      audio.removeEventListener("seeking", () => {
        setIsSetting(true);
      });
      audio.removeEventListener("seeked", () => {
        setIsSetting(false);
      });
    };
  }, [clickedTime, curTime, duration, totalDuration, setIsLoading, setIsSetting]);


  return (
    <PlayerWrapper>
      <audio
        data-name={title}
        id={slug}
        key={index}
        ref={audioRef}
        src={audioUrl}
        loop={loop}
        preload={preload}
      >
        Your browser does not support the <code>audio</code> element.
      </audio>

      <PlayerStyling className="controls">
        {showTrackInfo && (
          <TrackInfo
            location={location}
            title={title}
            slug={slug}
            trackIndex={index}
            trackName={trackName}
            trackArtist={trackArtist}
            playlistName={playlistName}
            trackImage={trackImage ? trackImage : null}
          />
        )}

        <ControlBarStyling>
          {playing && isCurrent ? (
            <Pause
              handleClick={() => {
                setPlaying(false)
                togglePlay(slug)
              }}
            />
          ) : isSeeking || loading ? (
            <Spinner />
          ) : (
              <Link
                to={`/blog/${playlistName}/${title}/${slug}`}

              >
                <Play
                    handleClick={() => {
                      setPlaying(true);
                      togglePlay(slug);

                    }}
                />
              </Link>
          )}

          {totalDuration && (
            <Bar
              totalDuration={totalDuration}
              curTime={curTime}
              duration={duration}
              onTimeUpdate={time => setClickedTime(time)}
            />
          )}
        </ControlBarStyling>
      </PlayerStyling>
    </PlayerWrapper>
  )
}

Player.defaultProps = {
  source: `${placeholderMusic}`,
  showTrackInfo: true,
  trackName: "Unknown",
  trackArtist: "Unknown Artist",
  trackImage: `${placeholderImg}`,
  loop: false,
  preload: "metadata",
}

export default Player
