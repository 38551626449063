import React from 'react'
import { BiPauseCircle } from "react-icons/bi";

const Pause = ({ handleClick }) => {
    return (
        <button aria-label="pause song" className="player__button" onClick={handleClick}>
            <BiPauseCircle />
        </button>
    )
}

export default Pause
