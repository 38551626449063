export function slugify(text) {
    /* eslint-disable */
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
    /* eslint-enable */
}


export function secondsToTime(secs) {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = Math.floor(secs % 60);

    const parts = [];

    if (hours > 0) {
        parts.push(String(hours).padStart(2, '0'));
    }

    parts.push(String(minutes).padStart(2, '0'));
    parts.push(String(seconds).padStart(2, '0'));

    return parts.join(':');
}
export function refactorSlug(x) {
    return x
    .replace(/\s+/g, "-")
    .toLowerCase()
}
