import React from 'react'
import { BsSkipBackward } from 'react-icons/bs';

const Backward = ({ handleClick, className }) => {
    return (
        <button aria-label="bprevious song" className={className} onClick={handleClick}>
            <BsSkipBackward />
        </button>
    )
}

export default Backward 
