import React, { Fragment } from "react"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TrackInfo = ({
  trackName,
  trackArtist,
  trackImage,
  playlistName,
  title,
  slug,
}) => {

  const image = getImage(trackImage)
  return (
    <div className="track-info">
      {trackImage && (
        <div className="track-image">
          <GatsbyImage image={image} alt={`${trackName}-${trackArtist}`} width={60} />
  
        </div>
      )}
      <div className="info-wrap">
        {trackArtist && (
          <Fragment>
            <span className="track-artist">{trackArtist}</span>
            <span className="track-divider">&nbsp; - &nbsp;</span>
          </Fragment>
        )}

          <AnchorLink
            to={`/blog/${playlistName}/${title}/${slug}#post-heading`}
            itemProp="url - track Name"
            className="track-name"
            title={trackName}
          />


       
      </div>
    </div>
  )
}

export default TrackInfo
