import React from "react"
import { ImSpinner3 } from "react-icons/im"


const Play = () => {
  return (
    <div aria-label="seeking" className="seeking" >
      <ImSpinner3 />
    </div>
  )
}

export default Play
