import React from "react"
import { AiOutlineInstagram } from "react-icons/ai"
import { AiFillFacebook } from "react-icons/ai"
import styled from "styled-components"

const ExternalLinks = styled.section`
  padding: var(--mainPadding) 0;
  display: flex;
  justify-content: space-in-between;
  p{
    padding-right: 10px;
  }
  a svg {
    padding:10px;
    width: 24px;
    height: 24px;
  }

  a:hover {
    color:var(--red);
  }
`

export default function Footer() {
  return (
    <footer>
      <ExternalLinks>
        <p>
          &copy;
          <a href="https://www.companychipaumire.com/" target="__blank">
            nora Chipaumire 
          </a>
          {" "}
          {new Date().getFullYear()}
        </p>
        <a href="https://www.instagram.com/norachipaumire" target="__blank">
          {" "}
          <AiOutlineInstagram />
        </a>
        <a href="https://www.facebook.com/norachipaumire" target="__blank">
          {" "}
          <AiFillFacebook />
        </a>
      </ExternalLinks>
    </footer>
  )
}
