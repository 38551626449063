import React from "react"
import { secondsToTime } from "./helpers/helpers"

const Bar = ({ duration, curTime, onTimeUpdate, totalDuration }) => {
  if (curTime === undefined) {
    curTime = 0
  }

  if (isNaN(duration) || duration === undefined) {
    duration = totalDuration
  }

  let curPercentage = (curTime / duration) * 100

  function calcClickedTime(e) {
    const bar = document.querySelector(".bar__progress")
    const barStart = bar.getBoundingClientRect().left + window.scrollX
    const barWidth = bar.offsetWidth
    const clickPositionInPage = e.pageX
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = duration / barWidth
  
    return timePerPixel * clickPositionInBar
  }

  function calcTouchTime(e) {
    const bar = document.querySelector(".bar__progress")
    const barStart = bar.getBoundingClientRect().left + window.scrollX
    const barWidth = bar.offsetWidth
    const clickPositionInPage = e.touches[0].pageX
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = duration / barWidth
    return timePerPixel * clickPositionInBar
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e))

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcClickedTime(eMove))
    }

    document.addEventListener("mousemove", updateTimeOnMove)
    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", updateTimeOnMove)
    })
  }

  function handleTouchDrag(e) {
    onTimeUpdate(calcTouchTime(e))

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcTouchTime(eMove))
    }

    document.addEventListener("touchmove", updateTimeOnMove)

    document.addEventListener("touchend", () => {
      document.removeEventListener("touchmove", updateTimeOnMove)
    })
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <div className="bar">
        <span className="bar__time">
          {!isNaN(curTime) ? secondsToTime(+curTime) : 0}
        </span>
        <div
          aria-label="progress bar"
          role="progressbar"
          tabIndex={0}
          className="bar__progress"
          style={{
            backgroundImage: `linear-gradient(to right, #777 ${curPercentage}%, #FFF 0)`,
          }}
          onMouseDown={e => handleTimeDrag(e)}
          onTouchStart={e => handleTouchDrag(e)}
        >
          <span
            className="bar__progress__knob"
            style={{ left: `${curPercentage - 1}%` }}
          />
        </div>
        <span className="bar__time">
          {!isNaN(duration) ? secondsToTime(+duration) : totalDuration}
        </span>
      </div>
    </div>
  )
}

export default Bar